<template>
  <div class="container">
    <h2 class="text-blue">{{ getBlog.title }}</h2>
    <div class="text-center">
      <img class="blog-img" :src="getBlog.image" alt="blog image" />
    </div>
    <br />
    <small v-if="error" class="text-danger">{{ error }}</small>
    <small v-if="getBlog.rejected" class="text-danger"
      >REJECTED BY {{ getBlog.rejectedBy }}</small
    >
    <small
      v-else
      :class="getBlog.published ? 'text-secondary' : 'text-danger'"
      >{{ getBlog.published ? "Published" : "Pending" }}</small
    >
    <br />
    <p v-html="getBlog.body" class="text-blue"></p>
    <div v-if="!getBlog.published && getProfile.roles.includes('admin')">
      <button
        class="btn btn-outline-success"
        :class="disabled ? 'disabled' : ''"
        @click.prevent="approveBlog"
      >
        APPROVE
      </button>
      <button
        class="btn btn-outline-danger"
        :class="disabled ? 'disabled' : ''"
        @click.prevent="rejectBlog"
      >
        REJECT
      </button>
    </div>

    <div v-if="getBlog.writtenBy === getUser.uid">
      <button class="btn btn-outline-success" @click.prevent="editBlog">
        EDIT
      </button>
    </div>

    <br />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Blog",
  computed: { ...mapGetters(["getBlog", "getProfile", "getUser"]) },
  data() {
    return {
      error: "",
      disabled: false,
    };
  },
  methods: {
    editBlog() {
      this.$router.push("/blogs/edit");
    },
    async approveBlog() {
      this.error = "";
      this.disabled = true;

      const approvedBlog = {
        id: this.getBlog.id,
        body: {
          published: true,
          publishDate: new Date(),
          approvedBy: this.getProfile.name,
          rejected: false,
          rejectedBy: "",
        },
      };

      const res = await this.$store.dispatch("updateBlog", approvedBlog);
      if (!res.isValid) {
        this.error = "An error has occured.";
        this.disabled = false;
        return;
      }

      this.error = "";
      this.disabled = false;
      this.$store.commit("blogStatus", null);
      this.$store.commit("writerProfile", null);
      this.$router.push("/blogs/pending");
    },
    async rejectBlog() {
      this.error = "";
      this.disabled = true;

      if (this.getBlog.rejected) {
        this.error = "";
        this.disabled = false;
        this.$store.commit("blogStatus", null);
        this.$store.commit("writerProfile", null);
        this.$router.push("/blogs/pending");
        return;
      }

      const rejectedBlog = {
        id: this.getBlog.id,
        body: {
          rejected: true,
          rejectedBy: this.getProfile.name,
        },
      };

      const res = await this.$store.dispatch("updateBlog", rejectedBlog);
      if (!res.isValid) {
        this.error = "An error has occured.";
        this.disabled = false;
        return;
      }

      this.error = "";
      this.disabled = false;
      this.$store.commit("blogStatus", null);
      this.$store.commit("writerProfile", null);
      this.$router.push("/blogs/pending");
    },
  },
};
</script>


<style scoped>
.blog-img {
  height: 400px;
}

.btn {
  margin-right: 10px !important;
}
</style>
